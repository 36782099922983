<template>
  <v-row>
    <v-col cols="12" sm="12" :md="displayFilters ? '10' : '12'" class="pr-0">
      <base-material-card color="primary" :icon="icon" inline class="px-5 py-3">
        <template #after-heading>
          <v-toolbar flat>
            <v-toolbar-title>{{ title }}</v-toolbar-title>
            <v-spacer />

            <v-text-field
              v-if="searchTable"
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              outlined
              dense
              style="max-width: 180px"
              class="mr-5 mt-3"
            />
            <v-select
              v-if="statusSearch"
              v-model="searchStatus"
              :items="status"
              item-value="label"
              item-text="label"
              label="Status"
              style="max-width: 150px"
              clearable
              outlined
              dense
              class="mt-3"
              @change="getData"
            ></v-select>
            <v-btn
              v-if="duplicate"
              :disabled="selected.length == 0"
              color="black"
              class="mx-2"
              rounded
              @click="action('duplicateSelected')"
            >
              Duplicate
            </v-btn>
            <v-btn
              v-if="showImport"
              color="primary"
              rounded
              depressed
              class="mx-2 text-normal"
              :to="url.import"
            >
              Import
            </v-btn>
            <dropdown-menu
              v-if="showLink"
              title="New"
              :items="links"
              icon="mdi-plus"
            />
            <v-btn
              v-if="showCreate"
              color="primary"
              class="mx-2"
              rounded
              :to="url.create"
            >
              Create
            </v-btn>
            <v-btn
              v-if="hasFilters"
              color="primary"
              class="mx-2"
              rounded
              @click="toogleFilter()"
            >
              Filter
            </v-btn>
          </v-toolbar>
        </template>
        <v-divider class="mt-3" />
        <div v-if="hasFilter" class="ml-3 py-3">
          <v-row>
            <v-chip-group
              v-for="(key, keyIndex) in filteredKeys"
              :key="keyIndex"
              class="cols-auto"
            >
              <div v-if="useNewFilters">
                <v-chip
                  v-for="(item, index) in filterTag[key]"
                  :key="index"
                  close-icon="mdi-close"
                  close
                  label
                  outlined
                  class="ma-1"
                  @click:close="removeFilter(filterTag[key], index, key)"
                >
                  {{ item }}
                </v-chip>
              </div>

              <div v-if="!useNewFilters && key !== 'skill_focus'">
                <v-chip
                  v-for="(item, index) in filterTag[key]"
                  :key="index"
                  close-icon="mdi-close"
                  close
                  label
                  outlined
                  class="ma-1"
                  @click:close="removeFilter(filterTag[key], index, key)"
                >
                  {{ filterData[keyIndex].data[item].value }}
                </v-chip>
              </div>
              <div v-if="!useNewFilters && key === 'skill_focus'">
                <v-chip
                  v-for="(item, index) in filterTag[key]"
                  :key="index"
                  close-icon="mdi-close"
                  close
                  label
                  outlined
                  class="ma-1"
                  @click:close="removeFilter(filterTag[key], index)"
                >
                  {{ filterSkillFocus(item) }}
                </v-chip>
              </div>
            </v-chip-group>
            <v-btn
              label
              text
              class="mt-1"
              color="primary"
              @click="clearFilters"
            >
              Clear filters
            </v-btn>
          </v-row>
        </div>
        <v-data-table
          v-model="selected"
          class="aq-tbl"
          :headers="headers"
          :items="filteredData"
          :search.sync="search"
          :loading="loadingPage"
          show-select
        >
          <template #[`item.lock`]="{ item }">
            <div v-if="item.customer_active">
              <div v-if="hasChangeAccess" class="text-left inline-edit-column">
                <div class="regular-label">
                  {{ item.lock ? 'Premium' : 'Free' }}
                </div>
                <div class="edit-option">
                  <v-select
                    v-model="item.lock"
                    :items="labels"
                    label=""
                    height="19"
                    small
                    dense
                    hide-details
                    @change="updateLabel(item)"
                  />
                </div>
              </div>
              <div v-else>{{ item.lock ? 'Premium' : 'Free' }}</div>
            </div>
          </template>
          <template #[`item.updated`]="{ item }">
            <div class="text-left">
              <span v-if="item.updated">
                {{ item.updated | formatDateNow }}
                <span v-if="item.update_user_name">
                  by {{ item.update_user_name }}
                </span>
              </span>
              <span v-else>
                {{ item.created | formatDateNow }}
                <span v-if="item.add_user_name">
                  by {{ item.add_user_name }}
                </span>
              </span>
            </div>
          </template>
          <template #[`item.actions`]="{ item }">
            <div v-if="item.customer_active">
              <v-tooltip bottom>
                <template #activator="{ on, attrs }">
                  <v-btn
                    fab
                    icon
                    x-small
                    v-bind="attrs"
                    v-on="on"
                    @click="action('duplicate', item)"
                  >
                    <v-icon small>mdi-content-duplicate</v-icon>
                  </v-btn>
                </template>
                <span>Duplicate</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template #activator="{ on, attrs }">
                  <v-btn
                    fab
                    icon
                    x-small
                    v-bind="attrs"
                    v-on="on"
                    @click="action('edit', item)"
                  >
                    <v-icon small>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Edit</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template #activator="{ on, attrs }">
                  <v-btn
                    fab
                    icon
                    x-small
                    v-bind="attrs"
                    v-on="on"
                    @click="action('delete', item)"
                  >
                    <v-icon small>mdi-delete</v-icon>
                  </v-btn>
                </template>
                <span>Delete</span>
              </v-tooltip>
            </div>
            <div v-else>
              <v-tooltip bottom>
                <template #activator="{ on, attrs }">
                  <v-btn
                    fab
                    icon
                    x-small
                    v-bind="attrs"
                    v-on="on"
                    @click="action('copy', item)"
                  >
                    <v-icon small>mdi-content-copy</v-icon>
                  </v-btn>
                </template>
                <span>Copy</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template #activator="{ on, attrs }">
                  <v-btn
                    :to="url.view + item.id"
                    fab
                    icon
                    x-small
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon small>mdi-eye</v-icon>
                  </v-btn>
                </template>
                <span>View</span>
              </v-tooltip>
            </div>
          </template>
        </v-data-table>
      </base-material-card>
    </v-col>
    <v-col
      v-if="displayFilters"
      transition="slide-x-reverse-transition"
      cols="12"
      sm="12"
      md="2"
      class="pr-0 pl-0"
    >
      <filters
        :module="sectionName"
        :filters.sync="datas"
        :display-filters.sync="displayFilters"
        :filtered-options.sync="filteredTags"
        :has-filter.sync="hasFilter"
        :skill-size.sync="skillSize"
        :use-new="useNewFilters"
        @filterKeys="filterKeys"
      />
    </v-col>
  </v-row>
</template>

<script>
import { mapState } from 'vuex';
import { db, usersCollection } from '@/utils/firebase';
import Filters from '@/components/Filters';
import generic from '@/mixins/generic.js';
import DropdownMenu from '@/components/base/DropdownMenu';
import { remoteConfig } from '@/utils/firebase';

export default {
  name: 'DataList',
  components: { Filters, DropdownMenu },
  mixins: [generic],
  props: {
    collectionName: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      default: 'Table Title',
    },
    icon: {
      type: String,
      default: 'mdi-alarm',
    },
    sectionName: {
      type: String,
      default: null,
      require: true,
    },
    headers: {
      type: Array,
      default: undefined,
    },
    searchTable: {
      type: Boolean,
      default: false,
    },
    statusSearch: {
      type: Boolean,
      default: false,
    },
    duplicate: {
      type: Boolean,
      default: false,
    },
    importData: {
      type: Boolean,
      default: false,
    },
    hasChangeAccess: {
      type: Boolean,
      default: false,
    },
    filterData: {
      type: Array,
      default: () => [],
    },
    hasFilters: {
      type: Boolean,
      default: false,
    },
    url: {
      type: Object,
      required: true,
      default: undefined,
    },
    links: {
      type: [Object, Array],
      default: () => [],
    },
    useNewFilters: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    displayFilters: false,
    filteredTags: {},
    filteredKeys: [],
    hasFilter: false,
    items: [],
    loadingPage: false,
    search: null,
    searchStatus: null,
    selected: [],
    skillSize: 0,
    users: {},
    labels: [
      { value: true, text: 'Premium' },
      { value: false, text: 'Free' },
    ],
    filterDatas: [],
  }),
  computed: {
    ...mapState({
      user: (state) => state.user.user,
      orgId: (state) => state.user.orgId,
      orgName: (state) => state.user.orgName,
      customerId: (state) => state.user.customerId,
      customerName: (state) => state.user.customerName,
      containsFilter: (state) => state.filter.containsFilter,
      moduleName: (state) => state.filter.moduleName,
      moduleOrg: (state) => state.filter.moduleOrg,
      tags: (state) => state.filter.tags,
    }),
    showCreate() {
      return this.url.create;
    },
    showLink() {
      return this.links.length > 0;
    },
    showImport() {
      return this.importData && this.url.import;
    },
    filterTag() {
      return this.filteredTags;
    },
    filteredData() {
      const vm = this;
      if (vm.hasFilter) {
        return vm.items.filter((item) => {
          return vm.filteredKeys.every((key, index) => {
            if (!vm.filteredTags[key].length) return true;
            // Loops again if item[key] is an array.
            if (item['filters'] && Array.isArray(item['filters'][key])) {
              return item['filters'][key].some((keyEle) => {
                return vm.filteredTags[key].some((option) => {
                  return option === keyEle;
                });
              });
            }

            if (Array.isArray(item[key])) {
              return item[key].some((keyEle) => {
                if (key === 'skill_focus')
                  return vm.filteredTags[key].some(
                    (option) => option === keyEle,
                  );
                return vm.filteredTags[key].some((option) => {
                  let optionKey = vm.filterData[index].data[option].key;
                  return optionKey === keyEle;
                });
              });
            }

            if (key === 'skills' && Array.isArray(item.categories)) {
              return item.categories.some((keyEle) => {
                return vm.filteredTags[key].some((option) => {
                  let optionKey = vm.filterData[index].data[option].key;
                  return optionKey === keyEle;
                });
              });
            }
          });
        });
      }
      return vm.items;
    },
    datas() {
      return this.useNewFilters ? this.filterDatas : this.filterData;
    },
  },
  watch: {
    orgId: function (value) {
      this.getUsers();
      this.getVariables(value);
      this.updateFilterData();
    },
  },
  created() {
    this.getUsers();
    this.getVariables();
    if (this.useNewFilters) {
      this.getFilters();
    } else {
      setTimeout(() => {
        this.loadFilters();
      }, 1000);
    }
  },
  methods: {
    getFilters() {
      let fbCollection = db
        .collection('organizations')
        .doc(this.orgId)
        .collection('filters')
        .where('allowedCollectionTypes', 'array-contains', this.sectionName);

      if (this.sectionName === 'skill') {
        fbCollection = db
          .collection('organizations')
          .doc(this.orgId)
          .collection('filters')
          .where('allowedCollectionTypes', 'array-contains-any', [
            'skill',
            'tactic',
          ]);
      }

      fbCollection.onSnapshot({ includeMetadataChanges: true }, (doc) => {
        let items = [];
        if (doc.docs.length > 0) {
          doc.forEach((item) => {
            let post = item.data();
            post.id = item.id;
            items.push(post);
          });
        }
        this.filterDatas = [...items];
      });
    },
    async getUsers() {
      try {
        let resSnap = usersCollection;
        if (this.orgId) {
          resSnap = resSnap.where('org_id', '==', this.orgId);
        }
        resSnap = resSnap.orderBy('name', 'desc');
        let querySnapshot = await resSnap.get();
        if (querySnapshot.docs.length > 0) {
          querySnapshot.forEach((doc) => {
            let post = doc.data();
            let user = {};
            user[doc.id] = post.name;
            this.users = Object.assign(this.users, user);
          });
        }
        this.getData();
      } catch (err) {
        this.$store.dispatch('notification/add', {
          type: 'error',
          message: `An error occurred : ${err.message}`,
        });
      }
    },
    getData() {
      this.loadingPage = true;
      let resSnap = db.collection(this.collectionName);
      if (this.orgId) {
        resSnap = resSnap.where('org_id', '==', this.orgId);
      }
      if (this.searchStatus) {
        resSnap = resSnap.where('status', '==', this.searchStatus);
      }

      this.items = [];

      resSnap.orderBy('created', 'desc').onSnapshot((querySnapshot) => {
        let postsArray = [];
        querySnapshot.forEach((doc) => {
          let post = doc.data();
          post.id = doc.id;
          post.owner = '';
          if (this.customerName) {
            post.owner = this.customerName;
          }

          post.update_user_name = post.update_user_id
            ? this.users[post.update_user_id]
            : '';
          post.add_user_name = post.add_user_id
            ? this.users[post.add_user_id]
            : '';

          post.removeItem = true;
          post.customer_active = true;
          if (this.customerId) {
            if (!post.customer_id) {
              post.owner = this.orgName;
              post.customer_active = false;
              post.removeItem = false;
            } else if (post.customer_id === this.customerId) {
              post.removeItem = false;
            }

            if (!post.removeItem) {
              postsArray.push(post);
            }
          } else if (!post.customer_id) {
            postsArray.push(post);
          }
        });
        this.items = postsArray;
        this.loadingPage = false;
      });
    },
    action(action, item) {
      if (action === 'duplicateSelected') {
        this.$emit('action', action, this.selected);
        this.selected = [];
      } else {
        this.$emit('action', action, item);
      }
    },
    filterKeys(keys) {
      this.filteredKeys = keys;
    },
    filterSkillFocus(tag) {
      let focus = this.skill_focus.find((val) => tag === val.id);
      return focus.name;
    },
    toogleFilter() {
      this.displayFilters = !this.displayFilters;
      if (!this.displayFilters) {
        this.updateState();
      }
    },
    loadFilters() {
      if (this.containsFilter && this.sectionName === this.moduleName) {
        this.hasFilter = true;
        this.displayFilters = true;
        this.filteredTags = this.tags;
        this.filterKeys(Object.keys(this.tags));
      }
    },
    updateState() {
      if (this.hasFilter) {
        this.$store.dispatch('filter/setFilter', this.hasFilter);
        this.$store.dispatch('filter/setModuleName', this.sectionName);
        this.$store.dispatch('filter/setFilteredTags', this.filteredTags);
      } else {
        if (this.containsFilter && this.sectionName === this.moduleName)
          this.$store.dispatch('filter/clearFilters');
      }
    },
    removeFilter(array, index, key = null) {
      if (index > -1) {
        array.splice(index, 1);
      }

      if (key && key === 'skills') {
        const focus = 'skill_focus';
        if (this.filteredTags[key].length === 0)
          this.filteredTags[focus].splice(0, this.filteredTags[focus].length);
      }

      let filters = this.filteredKeys.every((key) => {
        return this.filteredTags[key].length === 0;
      });

      if (filters) this.hasFilter = false;

      if (!this.useNewFilters) {
        this.skillSize = this.filteredTags['skills'].length;
      }

      this.filterKeys(Object.keys(this.filteredTags));
    },
    clearFilters() {
      this.filteredKeys.forEach((key) => {
        this.filteredTags[key].splice(0, this.filteredTags[key].length);
      });

      this.hasFilter = false;
      this.skillSize = 0;
      this.filterKeys(Object.keys(this.filteredTags));
    },
    updateFilterData() {
      this.displayFilters = false;
      this.clearFilters();
    },
    async updateLabel(item) {
      try {
        const node = {
          id: item.id,
          lock: item.lock,
          updated: new Date(),
          update_user_id: this.user.uid,
          changed: true,
        };
        await db.collection(this.collectionName).doc(item.id).update(node);
      } catch (err) {
        this.$store.dispatch('notification/add', {
          type: 'error',
          message: `An error occurred : ${err.message}`,
        });
      }
    },
  },
};
</script>
