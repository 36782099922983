<template>
  <v-card v-if="updateFilterStatus" elevation="2">
    <v-toolbar flat color="transparent">
      <v-toolbar-title>Filter by:</v-toolbar-title>
      <v-spacer />
      <v-btn icon @click="toogleDisplay()">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-divider />
    <div class="filter-content">
      <v-card-text v-for="(filter, index) in uniqueFilters" :key="index">
        <div v-if="useNew">
          <h4 class="subheading mb-2">{{ filter.name }}</h4>
          <v-chip-group
            v-model="filteredOptions[filter.name]"
            column
            multiple
            active-class="primary--text"
            @change="updateFilters()"
          >
            <v-chip
              v-for="item in filter.values"
              :key="item"
              :value="item"
              filter
              label
              outlined
              small
            >
              {{ item }}
            </v-chip>
          </v-chip-group>
        </div>
        <div v-if="!useNew && filter.key !== 'skill_focus'">
          <h4 class="subheading mb-2">{{ filter.Title }}</h4>
          <v-chip-group
            v-model="filteredOptions[filter.key]"
            column
            multiple
            active-class="primary--text"
            @change="updateFilters()"
          >
            <v-chip
              v-for="(item, dIndex) in filter.data"
              :key="dIndex"
              filter
              label
              small
              outlined
            >
              {{ item.value }}
            </v-chip>
          </v-chip-group>
        </div>
        <div
          v-if="filter.key === 'skill_focus' && hasSkills && skillLength > 0"
        >
          <h4 class="subheading mb-2">{{ filter.Title }}</h4>
          <v-chip-group
            v-model="filteredOptions[filter.key]"
            column
            multiple
            active-class="primary--text"
            @change="updateFilters()"
          >
            <v-chip
              v-for="(item, cIndex) in skillFocus"
              :key="cIndex"
              :value="item.id"
              filter
              label
              small
              outlined
            >
              {{ item.name }}
            </v-chip>
          </v-chip-group>
        </div>
      </v-card-text>
    </div>
  </v-card>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'DynamicFilters',
  props: {
    filters: {
      type: Array,
      default: () => [],
    },
    displayFilters: {
      type: Boolean,
      default: false,
    },
    module: {
      type: String,
      default: null,
    },
    skillSize: {
      type: Number,
      default: 0,
    },
    useNew: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    filteredOptions: {},
    filterKeys: [],
    skillFocus: [],
    hasFilter: false,
    hasSkills: false,
    skillsLength: 0,
    uniqueFilters: [],
  }),
  computed: {
    ...mapState({
      containsFilter: (state) => state.filter.containsFilter,
      moduleName: (state) => state.filter.moduleName,
      tags: (state) => state.filter.tags,
    }),
    updateFilterStatus() {
      return this.displayFilters;
    },
    skillLength() {
      return this.skillSize;
    },
  },
  watch: {
    skillsLength: function (val) {
      if (val > 0) this.filterSkillFocus();
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      const vm = this;
      if (vm.filters) {
        vm.filters.forEach((filter) => {
          if (filter.key === 'skills' && !vm.useNew) this.hasSkills = true;
          const key = vm.useNew ? filter.name : filter.key;
          vm.filterKeys.push(key);
          vm.filteredOptions[key] = [];
          filter.values = new Set(filter.values);
          vm.uniqueFilters.push(filter);
        });

        if (vm.containsFilter && vm.moduleName === vm.module) {
          vm.filteredOptions = vm.tags;
          this.$emit('filterKeys', vm.filterKeys);
        }
      }
    },
    hasFilters() {
      const vm = this;
      vm.hasFilter = vm.filterKeys.some((key) => {
        return vm.filteredOptions[key] && vm.filteredOptions[key].length > 0;
      });
    },
    updateFilters() {
      this.hasFilters();
      let keys = Object.keys(this.filteredOptions);
      if (
        !this.useNew &&
        this.filteredOptions['skills'] &&
        this.filteredOptions['skill_focus']
      ) {
        this.skillsLength = this.filteredOptions['skills'].length;
        this.$emit('update:skillSize', this.skillsLength);
      }
      this.$emit('filterKeys', keys);
      this.$emit('update:filteredOptions', this.filteredOptions);
      this.$emit('update:hasFilter', this.hasFilter);
    },
    filterSkillFocus() {
      const vm = this;
      const index = vm.filterKeys.indexOf('skill_focus');
      const skillIndex = vm.filterKeys.indexOf('skills');
      vm.skillFocus = vm.filters[index].data.filter((item) => {
        return vm.filteredOptions['skills'].some((skill) => {
          let categoryKey = vm.filters[skillIndex].data[skill].key;
          return item.categories.includes(categoryKey);
        });
      });
    },
    toogleDisplay() {
      this.updateState();
      this.$emit('update:displayFilters');
    },
    updateState() {
      if (this.hasFilter) {
        this.$store.dispatch('filter/setFilter', this.hasFilter);
        this.$store.dispatch('filter/setModuleName', this.module);
        this.$store.dispatch('filter/setFilteredTags', this.filteredOptions);
      }
    },
  },
};
</script>

<style scoped>
.filter-content {
  max-height: 710px;
  overflow-y: auto;
}
.w-10 {
  width: 10px !important;
}
</style>
